import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/render/project/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "typography"
    }}>{`Typography`}</h1>
    <p>{`Considered use of typography can present content clearly and more efficiently for every user. In Kuali's case, we universally uses `}<a parentName="p" {...{
        "href": "https://fonts.google.com/specimen/Roboto"
      }}>{`the Roboto font family`}</a>{`, available under an Apache 2.0 license at Google Fonts.`}</p>
    <p><img alt="image.png" src={require("./img/roboto.png")} /></p>
    <p>{`Roboto has a dual nature. It has a mechanical skeleton and the forms are largely geometric. At the same time, the font features friendly and open curves. While some grotesks distort their letterforms to force a rigid rhythm, Roboto doesn’t compromise, allowing letters to be settled into their natural width. This makes for a more natural reading rhythm more commonly found in humanist and serif types.`}</p>
    <h2 {...{
      "id": "adding-roboto-to-your-project"
    }}>{`Adding Roboto to Your Project`}</h2>
    <p>{`Including Roboto in your project can be done in one of two ways, with us generally preferring the first to avoid CSP and/or CDN issues. We recommend including the following weights and variants to your project; variants marked in asterisk are required inclusions, but you may omit others depending on your needs:`}</p>
    <ul>
      <li parentName="ul">{`Thin 100`}</li>
      <li parentName="ul">{`Light 300`}</li>
      <li parentName="ul">{`Regular 400`}{`*`}</li>
      <li parentName="ul">{`Regular 400 Italic`}{`*`}</li>
      <li parentName="ul">{`Medium 500`}{`*`}</li>
      <li parentName="ul">{`Medium 500 Italic`}{`*`}</li>
      <li parentName="ul">{`Bold 700`}{`*`}</li>
      <li parentName="ul">{`Bold 700 Italic`}{`*`}</li>
      <li parentName="ul">{`Black 900`}</li>
    </ul>
    <p>{`To include Roboto in your project, include these lines of HTML in the `}<inlineCode parentName="p">{`<head>`}</inlineCode>{` of your document:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<link rel="preconnect" href="https://fonts.gstatic.com" />
<link
  href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,400;1,500;1,700&display=swap"
  rel="stylesheet"
/>
`}</code></pre>
    <p>{`Note that you can choose to include specific weights and variants by adjusting the semicolon-separated aspects of the URL.`}</p>
    <h2 {...{
      "id": "standard-variants--type-scale"
    }}>{`Standard Variants & Type Scale`}</h2>
    <p>{`This type scale is intended to serve as a starting point for the typography in our applications, and provides styles for core HTML elements. It is not intended to be exhaustive and we expect each team to define necessary styles outside of this scale for their applications.`}</p>
    <p>{`The use of the specified `}<inlineCode parentName="p">{`Body 1`}</inlineCode>{` style for the base font in all applications is key to the compatibility of components working throughout the Kuali ecosystem of apps. Note that we have a `}<inlineCode parentName="p">{`14px`}</inlineCode>{` base font size, rather than the browser standard of `}<inlineCode parentName="p">{`16px`}</inlineCode>{` . In CSS, you can express this as `}<inlineCode parentName="p">{`html { font-size: 87.5%; }`}</inlineCode>{`, and then use `}<inlineCode parentName="p">{`rem`}</inlineCode>{` units to scale accordingly.`}</p>
    <p>{`While our type scale only specifies the Regular and Medium styles to simplify, drive legibility and reduce font loading to Regular, Regular Italic, Medium and Medium Italic there may be situations where use of the other styles is appropriate. See `}<em parentName="p">{`Adding Roboto to Your Project`}</em>{` for more details.`}</p>
    <blockquote>
      <p parentName="blockquote">{`We display both hard pixel and rem units for these variants, but in a browser implementation, you should always use `}<inlineCode parentName="p">{`rem`}</inlineCode>{` units for accessibility purposes. Additionally, we use unitless `}<inlineCode parentName="p">{`line-height`}</inlineCode>{` where possible to best scale with potential font size differences.`}</p>
    </blockquote>
    <h3 {...{
      "id": "type-scale-table"
    }}>{`Type Scale Table`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Style Name`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Font Size (px/rem)`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Line Height (px/unitless)`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Font Weight`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Letter Spacing (px/rem)`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Body 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14px/1rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20px/1.429`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`H1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32px/2.29rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48px/1.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.25px/0.018rem`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`H2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24px/1.74rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`36px/1.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`H3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20px/1.43rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`30px/1.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`500`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.15px/0.01rem`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`H4–6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16px/1.14rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24px/1.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`500`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.15px/0.01rem`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Subtitle 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16px/1.14rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24px/1.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.15px/0.01rem`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Subtitle 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14px/1rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20px/1.429`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`500`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.10px/0.007rem`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Caption/`}<inlineCode parentName="td">{`<small>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12px/0.857rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16px/1.333`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.4px/0.0286rem`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Small Caption`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`9px/0.643rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12px/1.333`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`500`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.4px/0.0286rem`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "type-scale-css"
    }}>{`Type Scale CSS`}</h3>
    <p>{`The following snippet uses the table styles above to provide core element styles for typography.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`html {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica,
    Arial, sans-serif;
  font-size: 87.5%; /** assuming a 16px browser base size, adjust to 14px */
  line-height: 1.429;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500; /** will be overridden for some below */
  line-height: 1.5;
}

h1 {
  font-size: 2.29rem;
}
h2 {
  font-size: 1.74rem;
}
h3 {
  font-size: 1.43rem;
}
h4,
h5,
h6 {
  font-size: 1.14rem;
}

h1,
h2 {
  font-weight: 400;
}

h1 {
  letter-spacing: 0.018rem;
}
h3,
h4,
h5,
h6 {
  letter-spacing: 0.01rem;
}

.subtitle1 {
  font-size: 1.14rem;
  font-weight: 400;
  letter-spacing: 0.01rem;
  line-height: 1.5;
}

.subtitle2 {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.007rem;
  line-height: 1.429;
}

small,
.smaller {
  font-size: 0.857rem;
  font-weight: 500;
  letter-spacing: 0.0286rem;
  line-height: 1.333;
}

.smaller {
  font-size: 0.643rem;
}
`}</code></pre>
    <h2 {...{
      "id": "other-typographic-elements"
    }}>{`Other Typographic Elements`}</h2>
    <p>{`In addition to core headers and paragraph text, we offer other styles for miscellaneous elements.`}</p>
    <h3 {...{
      "id": "lists-ol-ul"
    }}>{`Lists (`}<inlineCode parentName="h3">{`ol`}</inlineCode>{` /`}<inlineCode parentName="h3">{`ul`}</inlineCode>{`)`}</h3>
    <p>{`In long-form text blocks, lists should always be presented:`}</p>
    <ul>
      <li parentName="ul">{`With a list-item displayed`}</li>
      <li parentName="ul">{`With the list-item indicator flush-left with other paragraphs`}</li>
      <li parentName="ul">{`With subsequent lines of multiple-line list items indented to the point where text of the first line starts, past the list-item indicator`}</li>
    </ul>
    <p>{`Combined, you can use the following CSS to get lists going on your page:`}</p>
    <pre><code parentName="pre" {...{}}>{`ul, ol {
  list-style-position: outside;
  margin-left: 0;
  padding-left: 1.25rem;
}
`}</code></pre>
    <h3 {...{
      "id": "code-display-code-"
    }}>{`Code Display (`}<inlineCode parentName="h3">{`code`}</inlineCode>{` )`}</h3>
    <p>{`Code blocks should use the browser's default implementation, and are the one exception to our use of the Roboto font family in webpages. No special CSS is needed for these tags.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<pre>
  <code>
    console.log('Hello world!');
  </code>
</pre>
`}</code></pre>
    <h3 {...{
      "id": "field-rows--form-inputs"
    }}>{`Field Rows & Form Inputs`}</h3>
    <p>{`Form inputs have special considerations to take into account. Although both inputs and labels should still use the Roboto font family, labels must be styled to differentiate from normal text. A sample of how one might do this is provided below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`label {
  /** @extend .subtitle2; */ /** if you use SCSS or similar */
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.007rem;
  line-height: 1.429;
}
`}</code></pre>
    <h2 {...{
      "id": "typographic-colors--accessibility-guidelines"
    }}>{`Typographic Colors & Accessibility Guidelines`}</h2>
    <p>{`Our supported text style colors include the following primary variants; see `}<a parentName="p" {...{
        "href": "https://slite.com/api/public/notes/LlqliCbTQX/redirect"
      }}>{`the Colors Rudiment`}</a>{` for more details:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Primary`}</strong>{`: `}<inlineCode parentName="li">{`#111111`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Secondary: `}</strong><inlineCode parentName="li">{`#666666`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Link:`}</strong>{` `}<inlineCode parentName="li">{`#0066cc`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`White`}</strong>{`: `}<inlineCode parentName="li">{`#ffffff`}</inlineCode></li>
    </ul>
    <p>{`When using colored text in your app, always strive to meet `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/quickref/#contrast-minimum"
      }}>{`WCAG 2.1 AA standards for color contrast`}</a>{` between elements. Your text `}<strong parentName="p">{`must`}</strong>{` have, at minimum, a `}<strong parentName="p">{`3:1`}</strong>{` contrast ratio for text larger than 18pt (`}<inlineCode parentName="p">{`h1`}</inlineCode>{` –`}<inlineCode parentName="p">{`h3`}</inlineCode>{` ), and `}<strong parentName="p">{`4.5:1 `}</strong>{`contrast for text smaller than 18pt (everything else).`}</p>
    <h2 {...{
      "id": "print-styles"
    }}>{`Print Styles`}</h2>
    <p>{`Although our typography guidelines largely apply to websites and apps viewed on the screen, many Kuali modules utilize print functionality. All Kuali apps should also include a print stylesheet that addresses typographic concerns, with one simple set of CSS styles being provided below.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@page {
  margin: 52px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  break-after: avoid-page;
}

p,
li,
code {
  orphans: 2;
  widows: 2;
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      